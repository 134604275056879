<template>
    <div>
        <v-dialog v-model="exibirModalTutorial" width="90%" height="100vh" max-width="40vw">
            <v-card style="max-width: 100vh; height: 90vh">
                <v-toolbar color="blue" class="mb-8">
                    <v-flex  style="display: flex" class="pa-2">
                        <span style="width:95%;color:white;font-weight:bold;" >
                            Central de Ajuda 
                        </span>
                        <span style="width:5%;"> 
                            <v-btn x-small color="red" @click="$emit('setModalTutorial',false)" title="Fechar">
                                <v-icon x-small style="color:white;">mdi-close</v-icon>
                            </v-btn>
                        </span>
                    </v-flex>
                </v-toolbar>

                <div style="width:100%;padding-left:5%;padding-right: 5%;display: flex;flex-direction: row;justify-content: space-between;">
                    <v-btn color="info" style="width:45%;" @click="setExibicao('video')">
                        <v-icon style="margin-right:10px;">mdi-video</v-icon>Videos
                    </v-btn>
                    <v-btn color="info" style="width:45%;" @click="setExibicao('faq')">
                        <v-icon style="margin-right:10px;">mdi-frequently-asked-questions</v-icon> Perguntas e Respostas
                    </v-btn>
                    <div></div>
                </div>
                <v-card-text class="text-center">
                    <v-row style="margin-top:20px;">
                        <v-col cols="12" md="12" v-if="exibirVideo">
                            <iframe width="560"  height="315" :src="url"  frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>

                        </v-col>
                        <v-col cols="12" md="12" v-else>
                            <v-text-field
                                v-model="search" label="Buscar" single-line hide-details
                            />
                            <v-data-table data-table-dense-header-height='100px' :headers="headersTutoriais" 
                                :search="search" :items="listaDeFaqs" :items-per-page="5" 
                                style="width:100%;margin-top:5px;" >
                                <template  v-slot:[`item.pergunta`]="{ item }">

                                    <a @click="setModalTutorialResposta(item)" style="color:#494949;font-weight: 300;text-decoration: underline;">
                                        {{item.pergunta}}
                                    </a>

                                </template>
                            </v-data-table>  
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

            <ModalTutorialResposta v-if="exibirModalTutorialResposta" :exibirModalTutorialResposta="exibirModalTutorialResposta" @setModalTutorialResposta="setModalTutorialResposta()"
                        :resposta="faqSelecionado.resposta" :curtidasfaq="faqSelecionado.curtidasfaq" :idfaq="faqSelecionado.id" />
    </div>

</template>

<script>

import ModalTutorialResposta from './ModalTutorialResposta.vue';

export default {
    name: "ModalTutorial",
    data: () => ({
        headersTutoriais: [
            { text: "Pergunta", value: "pergunta" },
        ],
        search: "",
        pergunta: "",
        resposta: "",
        listaDeFaqs: [],
        faqSelecionado: {},
        exibirVideo: true,
        exibirModalTutorialResposta: false
    }),
    props:["exibirModalTutorial", "url", "categoria"],
    components:{
    ModalTutorialResposta
},
    methods: {
        setExibicao(itemExibir){
            if(itemExibir == 'video'){
                this.exibirVideo = true
            }else{
                this.exibirVideo = false
            }
        },

        buscarFaqs(){
                // let baseURL = this.$dados.BUILD_APIES_URL
                
                // this.$http.post(baseURL+"/faq/pergunta/endpoint/select", 
                // {pergunta: this.search,categorias:this.categoria,idcliente:this.$dados.BUILD_ID_CLIENTE,
                //     idusuario:localStorage.getItem('userid'),idsistema:2},
                // {headers:{'x-access-token':this.$dados.BUILD_TOKEN_APIES}})
                //     .then((response) => {
                //         this.listaDeFaqs = response.data
                //     })
                //     .catch(() => {
                //         console.error("Não foi possivel listar as FAQ's!");
                //     });
        },

        setModalTutorialResposta(faq){
            this.faqSelecionado = faq
            this.exibirModalTutorialResposta = !this.exibirModalTutorialResposta
        },
    },
    mounted() {
        this.buscarFaqs()
    },
};
</script>
